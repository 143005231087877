var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};
(function($) {
  var _templateClass = '.js-sku-list-sizes-v1';

  Drupal.behaviors.skuListSizesV1 = {
    attach: function (context) {
      var self = this;
      $(_templateClass, context).once('sku-list-size').each(function () {
        var $template = $(this);
        var $skuListItems = $template.find('.js-sku-list-sizes-button');
        var $skuListSizes = $template.find('.js-sku-list-sizes__list');
        var skuBaseId = $skuListItems.data('sku-base-id');
        var $orderItem = $template.find('.js-sku-list-sizes__list');

        if ($skuListSizes.children().length === 1) {
          $skuListItems.addClass('single-size-product');
        }

        // Arrange the sku in the proper order
        $orderItem.find('li.sku-list-sizes__item').sort(function (x, y) {
          return parseInt(x.getAttribute('data-sku-list-order')) - parseInt(y.getAttribute('data-sku-list-order'));
        }).appendTo($orderItem);

        // Set initial active state on PLP.
        if ($template.closest('.js-product-full').length < 1) {
          self.activateButton($skuListItems, skuBaseId);
        }

        $skuListItems.once('sku-list-item-click').on('click', function (e) {
          e.preventDefault();

          var $item = $(this);
          var skuBaseId = $item.data('skuBaseId');
          var skuData = prodcat.data.getSku(skuBaseId) || {};
          var $prodSelected = $item.closest('.js-product-grid-item, .js-product-full');
          var $skuImgMobile = $('.js-spp-link img.mobile-only', $prodSelected);
          var $skuImgPC = $('.js-spp-link img.pc-only', $prodSelected);
          var $badgeclass = $('.js-product-image-badge', $prodSelected);

          if ($badgeclass.length) {
            var badgeClassPrefix = 'product__image-badge--';
            var class_list = $badgeclass.attr('class').split(/\s+/);
            $(class_list).each(function () {
              className = String(this);
              if (className.indexOf(badgeClassPrefix) != -1) {
                $badgeclass.removeClass(className);
              }
            });
            if (skuData.MISC_FLAG_TEXT) {
              $badgeclass.addClass(badgeClassPrefix + skuData.MISC_FLAG_TEXT);
            }
          }

          self.activateButton($skuListItems, skuBaseId);

          if ($skuImgMobile.eq(0) && skuData.IMAGE_L) {
            $skuImgMobile.eq(0).attr('src', skuData.IMAGE_L);
          }

          if ($skuImgMobile.eq(0) && skuData.IMAGE_MPP_L && skuData.IMAGE_MPP_L[0]) {
            $skuImgMobile.eq(0).attr('src', skuData.IMAGE_MPP_L[0]);
          }

          if ($skuImgPC.eq(0) && skuData.LARGE_IMAGE[0]) {
            $skuImgPC.eq(0).attr('src', Array.isArray(skuData.LARGE_IMAGE) ? skuData.LARGE_IMAGE[0] : skuData.LARGE_IMAGE);
          }

          $item
            .closest('.js-product')
            .trigger('product.skuSelect', [skuBaseId]);
          if ($item.closest('.js-product-full').length > 0) {
            $(document).trigger('product.updateRoute', ['sku', skuBaseId]);
          } else {
            self.updateDeepLinks($skuListItems, skuBaseId);
          }
        });
      });
    },
    activateButton: function ($skuListItems, skuBaseId) {
      if ($skuListItems.length > 0 && !!skuBaseId) {
        $skuListItems
          .removeClass('active')
          .filter('[data-sku-base-id=' + skuBaseId + ']')
          .addClass('active');
      }
    },
    updateDeepLinks: function ($skuListItems, skuBaseId) {
      var self = this;
      var $product = $skuListItems.closest('.js-product');
      var $links = $product.find('.js-spp-link');
      var href = '';

      if ($links.length > 0) {
        // We only use first link, since all are the same under same parent.
        href = $links.first().attr('href');
        href = self.changeSku(href, skuBaseId);
        $links.attr('href', href);
      }
    },
    changeSku: function (str, skuBaseId) {
      var parts = str.split('#');

      if (parts.length > 0 && parts[1].indexOf('/sku/') === 0) {
        return parts[0] + '#/sku/' + skuBaseId;
      }

      return str;
    }
  };
  // Set active state on sku change.
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $skuSizeButtons = $('.js-sku-list-sizes-button', $(this));

    Drupal.behaviors.skuListSizesV1.activateButton($skuSizeButtons, skuBaseId);
  });
})(jQuery);
